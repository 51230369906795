/* header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: cornflowerblue;
}
header img {
    width: 5rem;
    height: auto;
}
header .header_menulist {
    margin-top: 1rem;
    list-style: none;
    display: flex;
    justify-content: center;
}
header .header_right {
    margin-top: 1rem;
    list-style: none;
    display: flex;
    justify-content: center;
}
header .dropdown-toggle::after {
    display: none !important;
}
header .custom-dropdown-toggle {
    background-color: cornflowerblue !important;
    border-color: cornflowerblue !important;
}
header li {
    display: inline-block;
    align-self: center;
    padding: 0 1rem;
    vertical-align: middle;
}
header li:hover {
    transform: scale(1.1);
    transition: 0.5s;
}
header .dropdown-menu {
    --bs-dropdown-min-width: 11rem !important;
} */

.navbar {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    width: '100%';
}
.navbar-toggler {
    border: none !important;
}
.dropdown-toggle::after {
    display:none !important;
}
.menuDepth2 {
    font-size: 20px;
}
@media (min-width: 768px) {
    .menuDepth2 > a span .menuChevron {
        display: none;
    }
    .menuDepth3 > a span .menuChevron {
        transform: rotate(270deg);
    }
}
@media (max-width: 767px) {
    .dropdown-toggle.show.nav-link span .menuChevron {
        transform: rotate(180deg);
    }
}
@media (min-width: 768px) {
    .nav-link:hover {
        color: #fb0404 !important;
    }
    .navbar-nav .nav-link.active, .navbar-nav .nav-link.show  {
        color: var(--bs-nav-link-color) !important;
    }
    .nav-item.dropdown-center:hover > .dropdown-menu {
        display: block;
        margin-top: 0;
    }
    .nav-item.dropdown-center > .dropdown-menu {
        display: none;
    }
    .nav-item.dropend:hover .dropdown-menu {
        display: block;
    }
    .nav-item.dropend .dropdown-menu {
        display: none;
    }
    .nav-item.dropdown:hover .dropdown-menu-md-end {
        display: block;
        margin-top: 0;
    }
    .nav-item.dropdown .dropdown-menu-md-end {
        display: none;
    }
    .dropdown-menu-md-end .nav-link:hover {
        color: #000000 !important;
    }
    .dropdown-menu-md-end .nav-link {
        padding-left: 8px;
        color: #777777;
    }
    .dropend .dropdown-menu[data-bs-popper] {
        margin-left: 0 !important;
        border-color: rgb(205, 205, 205);
    }
    .dropdown-toggle.nav-link::after {
        border: 0;
    }
}
@media (max-width: 767px) {
    div.dropdown-menu.show {
        padding-left: 16px;
        border: 0 !important;
    }
}

#offcanvasNavbar-expand-md {
    width: 215px;
}
#offcanvasNavbar-expand-md.show #profile-dropdown {
    display: none;
}
#offcanvasNavbar-expand-md:not(.show) #profile-below-btn {
    display: none;
}