
.board-thumbnail {
    width: 5rem;
    height: 3rem;
}

.board-no-wrapper { width: 4rem; text-align: center; }

.modal-body:has(.board-content),
.modal-body:has(.jodit-react-container) { max-height: 85vh; overflow-y: auto; }

.modal-content .board-detail-form { padding: 0 2rem; box-sizing: content-box; }

.table .board-detail-info-2 { background: #eee; width: 30%; vertical-align: middle; }
.table .board-detail-data-2 { width: 70%; vertical-align: middle; }
.table .board-detail-info { background: #eee; width: 20%; vertical-align: middle; }
.table .board-detail-data { width: 30%; vertical-align: middle; }
.table .board-table-space { border: none; }
/* .table .board-content { text-align: center; } */
.table .board-content p { text-align: left; }
.table .board-content *:not(*:has(img)) { text-align: left; }

.table .bl-n { border-left: none; }
.table .br-n { border-right: none; }

.table .post-article, .table .prev-article { cursor: pointer; }

.reply-wrap { width: 90%; margin: 2rem auto; }

.comment-wrap { 
    border-bottom: 1px solid #aaa;
    padding: 1rem;
}
.comment-wrap.sub-reply { padding-left: 3rem; background-color: #f6f6f6; }
.modal-content .reply-wrap textarea.form-control { align-content: normal; }

.button-wrap.row .col button.btn {width: 100%;}

.button-wrap.hidden { display: none; }

.modal:has(#inquiryModal) { background-color: rgba(0,0,0,0.5); }

.ta-c { text-align: center; }
.ta-r { text-align: right; }

.pd-n { padding: 0; }
.pr-n { padding-right: 0; }

.table .attch-wrap { padding: 0.5rem 1rem; overflow-x: hidden; }
.table .attch-wrap div { white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis; }
/* .board-detail-form .table tr td:first-of-type:not(td[colspan]) { width: 50px; overflow: hidden; } */
.board-detail-form .table tr { max-width: 95vw; }
.board-detail-form td[colspan]:not(.board-content, .board-content *, .jodit-wysiwyg, .jodit-wysiwyg *) { white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis; }

@media (min-width: 425px) {
    .board-sub-info { font-size: 14px; color: gray; }
}
@media (max-width: 425px) {
    .board-sub-info { font-size: 13px; color: gray; }
}
@media (max-width: 320px) {
    .board-sub-info { font-size: 11px; color: gray; }
}
.board-detail-form td.board-content { padding: 0; }

.board-title.modal-header { border-top-left-radius: 0.3rem; border-top-right-radius: 0.3rem; }

.attch-file { cursor: pointer; }
.attch-file:hover { text-decoration: underline; }

@media (min-width: 768px) {
    .date-control.form-control,
    .notice-control.form-control { display: inline-block; width: 80%; }
    .date-switch.form-check,
    .notice-switch.form-check { display: inline-block; width: 20%; }
}

.loading-wrap.show {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(255,255,255,0.5);
    z-index: 300;
}
.loading-wrap.hidden {display: none;}
@media (max-width: 767px) {
    .loading-wrap.show {
        width: 70vw;
        left: 15vw;
    }
}

#board-dropdown + div.dropdown-menu.show {
    border: 1px solid lightgray !important;
    padding-left: 0;
}