@media(max-width: 768px) {
    .content.datatable-content {margin: 1rem 0.6rem;}
    /* .content.datatable-content table {font-size: 0.71rem;} */
    /* .datatable-content .container-fluid {margin-bottom: 1rem;} */
}
.hidden,
.hidden.form-check,
.hidden.form-control,
.hidden.form-control.half-width,
.hidden.btn {display: none;}
.table .inp-title {
    width: 10%;
    text-align: center;
    vertical-align: middle;
    background-color: #e8f1fb;
}
.appr_type_td  {line-height: 27px; background-color: #e8f1fb; text-align: center;}

#apprLineBody{
	line-height: 20px;
	background-color: #ffffff
}

.apprbox_table tbody tr td.appr_contents {  color: #454545; line-height: 13px; word-break:break-all; vertical-align: top}
.apprbox_table tbody tr td.border_bottom {  color: #454545; border-bottom: 0px;}

.inp-container {width: 70%;}

.inp-container_second {width: 30%;}

.button-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.table_02 .information_title_table .title_cell .file_atach_table .frm_name .file_attach_area .board_01 .progress, input, select, textarea{
	font-family: Arial, Malgun Gothic, Microsoft Sans Serif;
	font-size: 10pt;
	color: #454545;
}

.appr_line_div {
    max-height: 265px;
    font-size: 0.9em;
    overflow: auto;
  }
  
  @media (max-width: 600px) {
    .appr_line_div {
      max-height: 200px;
      font-size: 0.8em;
    }
  }

  
.information_title_table .title_cell {
	font-weight: bold;
	color: #1b4c84;
	text-decoration: none;
	background-color: #a6c8ef;
}
.appr_contents {line-height: 24px; word-break:break-all; vertical-align: top; text-align: center;}

.button-wrap:not(.modal-footer .button-wrap) button { width : 30%; margin: 1rem; }

.half-width.form-control {width: 35%; display: inline-block}



/* login page */
.modal-content .hidden {display: none;}

.modal-footer:has(.button-wrap) {justify-content: center; flex-wrap: nowrap;}
.modal-footer .button-wrap button {min-width: 40%; margin: 1rem;}

#signInBody2 table {width: 100%;}
#signInBody2 tr > td:first-child {width: 30%;}
#signInBody2 tr > td:last-child {width: 70%;}
#signInBody2 tr > td:last-child > input,
#signInBody2 tr > td:last-child > select {width: 100%; height: 3vh;}

@media print {
  @page {
    margin: 0; /* 여백을 0으로 설정 */
  }
  
  body {
    margin: 0; /* 페이지 내 여백도 제거 */
    padding-top: 20mm; /* 원한다면 내부 여백을 설정 */
    padding-left: 15mm; /* 원한다면 내부 여백을 설정 */
    padding-right: 15mm; /* 원한다면 내부 여백을 설정 */
    padding-bottom: 10mm; /* 원한다면 내부 여백을 설정 */
  }

  .no-print {
    display: none !important;
  }
}