body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-item {
  overflow : hidden;
  height: 30vh;
  line-height: 30vh;
  vertical-align: middle;
  background-color: #555
}

@media (max-height: 600px) {
  .carousel-item {
    height: 25vw;
    line-height: 25vw;
  }
}

.carousel-title-wrap .carousel-title:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.carousel-title-wrap .carousel-title:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.carousel-title {
  max-width: 100%;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-weight: 700;
  padding-left: 10px;
  overflow: hidden;
  background-color: #dd0404;
  transition: 0.5s;
}

.carousel-title span {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.carousel-title.active {
  cursor: pointer;
  background-color: #990404;
  transition: 0.5s;
}

.carousel-caption {
  text-shadow: -1px 0px #000, 0px 1px #000, 1px 0px #000, 0px -1px #000;
  transition: 0.5s;
}

.infinite-scroll-component {
  overflow: unset!important;
}