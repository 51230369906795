/* 휴가전체검색 */
.holidayStateSearch .row .col:has(input[name="searchStr"]){
    display: none;
}
/* 결재지연관리 */
.delayApprDocSearch .container-fluid .row .col:has(input[name="searchStr"]){
    display: none;
}
.delayApprDocSearch .container-fluid .row div:has(button[name="searchBtn"]){
    display: none !important;
}

.tooltip-inner {
    max-width: none;
}