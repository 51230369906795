/* ::-webkit-scrollbar {
    display: none;
} */

.myInfo > div, .myInfo table {
    margin-bottom: 15px;
}

.myInfo button {
    margin-right: 5px;
}

.myInfo td {
    border: none;
}

/* 내 정보 카드 */
@media (min-width: 768px) {
    .myInfo .infoCard {
        border: 2px solid black;
        margin-bottom: 30px;
    }

    .createDocBtn {
        width: 100%;
    }

    .receiptLists {
        height: 60dvh;
        overflow-y: auto;
    }

    @media (max-height: 830px) {
        .receiptLists {
            height: 50dvh;
            overflow-y: auto;
        }
    }
}

@media (max-width: 767px) {
    .myInfo .infoCard {
        width: 90vw;
        border: 2px solid black;
        margin-bottom: 30px;
    }

    .createDocBtn {
        width: 100%;
    }
    
    .receiptLists {
        height: 70dvh;
        overflow-y: auto;
    }

    @media (max-height: 830px) {
        .receiptLists {
            height: 60dvh;
            overflow-y: auto;
        }
    }
}
        
.pictureDiv {
    width: 100%;
    position: absolute;
    top: 0px;
    height: 75%;
}

.pictureChange {
    display: none;
}

.infoCard #pictureChangeRealBtn {
    position: absolute;
    bottom: 2px;
    left: 30%;
}

.myInfo .myInfoPicture {
    width: 128px;
    position: relative;
    padding: 0px;
    border: 1px solid black;
    background-size: 128px 75%;
    background-repeat: no-repeat;
    border: 1px solid var(--bs-border-color);
    border-radius: 10px;
}

.adminPicture {
    position: relative;
    height: 200px;
    margin-bottom: 1rem;
    overflow: auto;
}

.adminPicture > .myInfoPicture {
    width: 150px;
    height: 100%;
    float: left;
    margin-right: 10px;
    background-size: 150px 100%;
    background-repeat: no-repeat;
    border: 1px solid var(--bs-border-color);
    border-radius: 10px;
}

.adminPicture > .myInfoButtonBox {
    width: 150px;
    height: 100%;
    position: relative;
    float: left;
}

.adminPicture > .myInfoButtonBox > button {
    float: left;
    margin-right: 5px;
}

.form-floating > .notTextGroup {
    position: absolute;
    bottom: 4px;
}

.form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.825rem;
}

.cursorDefault, .cursorDefault * {
    cursor: default;
}

.previewReceipt {
    width: 100%;
}

.createDocBtn {
    margin-top: 20px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
}

.receiptList {
    display: flex;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
}

.receiptList > div:nth-child(1) {
    display: flex;
    align-items: center;
}

.createReceipt {
    float: right;
    font-size: 18px;
}

.receiptOne {
    display: block;
    margin-left: 15px;
}

.receiptOne > div:nth-child(2) {
    font-size: 12px;
}

.margin-left-5 {
    position: left;
    margin-left: 5px;
}

.myInfoDetail input[type='radio'], #userInfo input[type='radio'], #userInfo span, .myInfoDetail span {
    margin-left : 5px;
}

#user_id_pop_cate4 {
    width: 80%;
    display: inline-block;
}

#idCheck {
    display: inline-block;
    margin-left: 10px;
}

#pwModifyBtn {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    margin: 0 2.5%;
    width: 35%;
}