/* 전체 레이아웃. 그림자 효과같은 것을 주면 좋다 */
.react-datepicker {
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25); */
}

.react-datepicker__month-container {

}

.react-datepicker__header{
 
}

/* @media (max-width: 768px) {
    .react-datepicker__input-container {
        width: 100%;
    }
}

@media (min-width: 769px) {
    .react-datepicker__input-container {
        width: 50%;
    }
} */

/* 최상단에 뜨는 연도와 월 */
.react-datepicker__current-month{
}

/* 요일들 */
.react-datepicker__day-name{
}

/* 날짜들을 담는 레이아웃 */
.react-datepicker__month{

}

/* day: 주말 날짜 */
.react-datepicker__day:nth-child(1){ 
    color:red; /* 일요일 날짜*/
}
.react-datepicker__day:nth-child(7){
    color:#8685FF; /* 토요일 날짜 */
}

/* day-name: 요일 */
.react-datepicker__day-name:nth-child(1){
    color: #FF5555; /* 일요일 */
}
.react-datepicker__day-name:nth-child(7){
    color:#8685FF; /* 토요일 */
}

/* 일반 날짜 */
.react-datepicker__day{
}

/* 선택된 날짜 */
.react-datepicker__day--selected{

}


/* highlighted된 날짜 */
.react-datepicker__day--highlighted{

} 

/* 날짜에 마우스를 올릴 때 */
.react-datepicker__day:hover {

}
