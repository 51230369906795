.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-wrapper {
  min-width: 330px;
}
.textBox {
  text-align: left;
  margin: 20px 0px;
}

.image-container {
  text-align: center;
  width: 100%;
}

.responsive-image {
  width: 60%;
  height: auto; /* 원본 비율 유지 */
}