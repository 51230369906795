body {
  font-family: 'Noto Sans KR', sans-serif;
}

.App{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.main{
  display: flex;
  flex-grow: 1;
}

.content-container{
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 1300px) {
  .content-container{
    max-width: 1200px;
  }
  .content{
    max-width: 1200px;
  }
}
.btn.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #FB0404 !important;
  --bs-btn-border-color: #FB0404 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #DD0404 !important;
  --bs-btn-hover-border-color: #DD0404 !important;
  --bs-btn-focus-shadow-rgb: 225, 83, 97 !important;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #DD0404 !important;
  --bs-btn-active-border-color: #DD0404 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FB0404 !important;
  --bs-btn-disabled-border-color: #FB0404 !important;
}
.btn.btn-danger {
  --bs-btn-color: #dc3545 !important;
  --bs-btn-bg: #0000 !important;
  --bs-btn-border-color: #dc3545 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #dc3545 !important;
  --bs-btn-hover-border-color: #dc3545 !important;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #dc3545 !important;
  --bs-btn-active-border-color: #dc3545 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #dc3545 !important;
  --bs-btn-disabled-bg: #0000 !important;
  --bs-btn-disabled-border-color: #dc3545 !important;
}
.col-auto:has(button[name="searchBtn"]) .btn.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #FB0404 !important;
  --bs-btn-border-color: #FB0404 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #DD0404 !important;
  --bs-btn-hover-border-color: #DD0404 !important;
  --bs-btn-focus-shadow-rgb: 225, 83, 97 !important;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #DD0404 !important;
  --bs-btn-active-border-color: #DD0404 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FB0404 !important;
  --bs-btn-disabled-border-color: #FB0404 !important;
}
.option .btn.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529 !important;
  --bs-btn-border-color: #212529 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649 !important;
  --bs-btn-hover-border-color: #373b3e !important;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154 !important;
  --bs-btn-active-border-color: #373b3e !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529 !important;
  --bs-btn-disabled-border-color: #212529 !important;
}

.content{
  margin: 1rem;
}

.container-fluid .row {
  align-items: center;
}

@media (max-width: 767px) {
  .rowCount p {
    margin-top: 0.5rem;
  }
  table {
    margin-top: 0;
  }
}
.rowCount p {
  margin-bottom: 0 !important;
}
table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  border-top: 2px solid #fb040498;
  margin-top: 0.5rem;
}
.table-normal-border-top {
  border-top: 1px solid var(--bs-border-color);
}

th {
  background-color: #f8f9fa !important;
  font-weight: 500;
  color: var(--bs-gray-900);
}

th, td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  color: var(--bs-gray-700);
}

.inner-border th, .inner-border td {
  border-right: 1px solid #ddd;
}

.inner-border th:last-child, .inner-border td:last-child {
  border-right: 0 !important;
}

.tooltip-inner{
  text-align: left;
}

.option {
  display: table-cell;
  width: 1px; /* Ensure cell width fits content */
  white-space: nowrap; /* Prevent wrapping */
}

.selected-tree {
  background-color: #f3f3f3;
}

.modal-content .row  {
  margin-top: 5px;
}

.modal-content .row * {
  align-content: center;
}

.pagination-container {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
  
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination-container li {
  display: inline-block; /* 가로로 배치되도록 설정 */
  align-self: center; /* 세로 방향 가운데 정렬 */
  vertical-align: middle;
}

.pagination li a {
  display: block;
  padding: 8px 16px;
}

.page-item {
  margin: 0 5px;
}
  
.page-link {
  display: block;
  padding: 0.5rem 0.75rem;
  color: #fb0404;
  border: none;
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;
}
  
.page-link:hover {
  color: #fff;
  background-color: #fb0404;
  border-color: #dee2e6;
}
.pagination li:not(.active, .disabled):hover a {
  color: #fff !important;
}
.pagination li:not(.active, .disabled):hover {
  background-color: #fb0404;
}
.pagination li:not(.active, .disabled) a {
  z-index: 1;
  color: #fb0404;
  font-weight: 1000;
}

.pagination .active a {
  color: #fff !important;
  pointer-events: none;
  font-weight: 1000;
}
.pagination li.active {
  background-color: #fb0404;
}
.pagination .disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: not-allowed;
}

.page-link:disabled {
  color: #6c757d !important;
  pointer-events: none;
  cursor: not-allowed;
}

.form-check-input {
  margin-top: 6px;
}
.form-check-input:checked {
  background-color: #fb0404;
  border-color: #fb0404;
}
.form-check-inline .form-check-label {
  padding-left: 0.3rem;
}

.nav-tabs .nav-item .nav-link {
  color: #5b5b5b;
}
.nav-tabs .nav-item .nav-link.active {
  color: #fb0404;
}

.spinner_container{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.spinner {
  width: 30%; /* 부모 크기의 30% */
  height: 30%; /* 부모 크기의 30% */
  animation: spin 2s linear infinite;
}

/* 회전 애니메이션 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* scroll bar */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
    background-clip: padding-box;
    border: 1px solid transparent;
}
::-webkit-scrollbar-track {
    background-color: grey;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}