.card-style {
    background-color: #f8f9fa;
    padding: 10px;
    text-align: center;
    margin: 5px 0;
    box-shadow: inset 0 0 0 1px #ccc;
}

.detail-card-style {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #ddd;
    height: 300px;
    margin: 10px 0;
}

@media (max-width: 768px) {
    .eas-main-container .card-style,
    .detail-card-style {
        width: 100%; /* 혹은 적절한 너비로 조정 */
        padding: 0; /* 필요에 따라 패딩을 0으로 설정 */
        overflow-x: hidden; /* 필요하면 가로 스크롤을 숨김 */
    }
}

@media (min-width: 769px) and (max-width: 1110px) {
    .custom-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media (min-width: 1111px) {
    .custom-lg {
        flex: 1 0;
    }
}

@media (min-width: 769px) {
    .custom-show-769 {
        display: block !important;
    }
}

@media (max-width: 768px) {
    .custom-show-769 {
        display: none !important;
    }
}

.table-wrap-scroll {
    overflow: hidden;
}

.table-wrap-scroll.scroll {
    min-height: 150px;
    max-height: 150px;
    /* height: 200px; */
    overflow-y: scroll;
}

.table-wrap-scroll td {
    text-align: left;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}
