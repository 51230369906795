@media(max-width: 768px) {
    .content.datatable-content {margin: 1rem 0.6rem;}
    /* .content.datatable-content table {font-size: 0.71rem;} */
    /* .datatable-content .container-fluid {margin-bottom: 1rem;} */
}
.hidden,
.hidden.form-check,
.hidden.form-control,
.hidden.form-control.half-width,
.hidden.btn {display: none;}
.table .inp-title {
    width: 30%;
    text-align: center;
    vertical-align: middle;
    background-color: #e8f1fb;
}

.inp-container {width: 70%;}

.button-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button-wrap:not(.modal-footer .button-wrap) button { width : 30%; margin: 1rem; text-wrap: nowrap; }

.half-width.form-control {width: 45%; display: inline-block}



/* login page */
.modal-content .hidden {display: none;}

.modal-footer:has(.button-wrap) {justify-content: center; flex-wrap: nowrap;}
.modal-footer .button-wrap button {min-width: 40%; margin: 1rem;}

#signInBody2 table {width: 100%;}
#signInBody2 tr > td:first-child {width: 30%;}
#signInBody2 tr > td:last-child {width: 70%;}
#signInBody2 tr > td:last-child > input,
#signInBody2 tr > td:last-child > select {width: 100%; height: 3vh;}